@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'msgothic';
  src: url('/src/Fonts/W95FA.otf');
}

@font-face {
  font-family: 'PIXELITE';
  src: url('/src/Fonts/PIXELITE.ttf');
}

@font-face {
  font-family: 'PixelifySans';
  src: url('/src/Fonts/PixelifySans-VariableFont_wght.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0100-024F, U+0400-04FF, U+0500-052F;
}

/* @font-face {
  font-family: 'DotGothic16JP';
  src: url('/src/Fonts/DotGothic16-Regular.ttf') format('truetype');
  unicode-range: 
} */

@font-face {
  font-family: 'LanaPixel';
  src: url('/src/Fonts/LanaPixel.ttf') format('truetype');
  unicode-range: 
    U+0000-00FF,     /* Basic Latin */
    U+0100-024F,     /* Latin Extended-A/B */
    U+0370-03FF,     /* Greek */
    U+0400-04FF,     /* Cyrillic */
    U+0500-052F,     /* Cyrillic Supplement (includes Ukrainian) */
    U+3040-309F,     /* Hiragana */
    U+30A0-30FF,     /* Katakana */
    U+AC00-D7AF,     /* Hangul */
    U+4E00-9FFF,     /* CJK Unified Ideographs (kanji) */
    U+0400-04FF,
    U+0500-052F;
}

body {
  overflow: hidden;
  font-family: 'msgothic', 'LanaPixel', sans-serif;
  font-size: large;
  font-weight: lighter;
  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);


  /* no anti-aliasing */
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'PIXELITE', 'LanaPixel', sans-serif;
  font-weight: 400;
  font-size: xx-large;
}

::-webkit-scrollbar {
  width: 10px;
}

.nervessStyle {
  cursor: url('/src/Assets/Img/Cursors/nervess_cursor_pointer.png'), auto;

  ::-webkit-scrollbar-thumb {
    border: 2px solid #ff00ff;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff00ff;
  }
}

.retroStyle {
  cursor: url('/src/Assets/Img/Cursors/retro_cursor_pointer.png'), auto;

  ::-webkit-scrollbar-thumb {
    border: 2px solid #10ce20;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #10ce20;
  }
}

.christmasStyle {
  ::-webkit-scrollbar-thumb {
    border: 2px solid #ff0000;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff0000;
  }
}

.oldMacStyle {
  ::-webkit-scrollbar-thumb {
    border: 2px solid rgba(220, 38, 38);
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(220, 38, 38);
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #000;
  }

}

.halloweenStyle {
  ::-webkit-scrollbar-thumb {
    border: 2px solid #ff5100;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff5100;
  }
}
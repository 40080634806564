.retro {
    body {
        /* Add some bloom */
        animation: neon-glow 100ms ease-in-out infinite;
        /* Blur the edges */
        overflow: hidden;
    }

    /* Neon glow animation */
    @keyframes neon-glow {
        0% {
            filter: blur(0.1px) brightness(1.1);
        }
        50% {
            filter: blur(0.2px) brightness(1.2);
        }
        100% {
            filter: blur(0.1px) brightness(1.1);
        }
    }

    /* Apply animation to h1 element */
    p, a, li, h1, img, button, canvas {
        animation: quick-change 1ms ease-in-out infinite;
        /* Glow */
        text-shadow: 0 0 1px rgb(124, 58, 237), 0 0 4px;
    }

    /* Quick position change animation */
    @keyframes quick-change {
        0% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(1px, 1px);
        }
        100% {
            transform: translate(0, 0);
        }
    }
}

.scanlines {
    background: repeating-linear-gradient(180deg, rgba(0, 250, 0, 1), rgba(0, 250, 0, 1), rgba(0, 250, 250, 1) 4px, transparent 4px, transparent 8px);
    mix-blend-mode: saturation;
    animation: neon 100ms linear infinite;
}

.scanlines-header {
    background: repeating-linear-gradient(180deg, rgba(200, 200, 200, 1), rgba(200, 200, 200, 1), rgba(200, 200, 200, 1) 4px, transparent 4px, transparent 8px);
    mix-blend-mode: overlay;
    animation: neon 100ms linear infinite;
}

.scanlines-entire-screen {
    background: repeating-linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(1, 0, 0, 0.1) 4px, transparent 4px, transparent 8px);
    background-size: 100%;
    mix-blend-mode: color-burn;
    animation: neon 100ms linear infinite;

    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    pointer-events: none;
}

@keyframes neon {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

.imageneonretro {
    filter: drop-shadow(0 0 0.75rem #ff6cf8cb);
}

.imageneoncmd {
    filter: drop-shadow(0 0 0.75rem #15ff00cb);
}

.imageneonchristmas {
    filter: drop-shadow(0 0 0.75rem #ff1414cb);
}

.imageneonhalloween {
    filter: drop-shadow(0 0 0.75rem #f01b14cb);
}

.imageneonoldmac {
    filter: drop-shadow(0 0 0.75rem rgba(220, 38, 38));
}
// Mobile screen 640px height
@media (max-width: 1024px) {
    .logo {
        p {
            // Size text in px
            font-size: 8px;
            // Negative margins
            margin: -5px;
        }
    }
    .loading {
        p {
            // Size text in px
            font-size: 10px;
        }
    }
}

// After 1024px width and 1440px height
@media (min-width: 1024px) and (max-height: 1440px) {
    .logo {
        p {
            // Size text in px
            font-size: 9px;
            // Negative margins
            margin: -3px;
        }
    }
}

// After 1440px width
@media (min-width: 1440px) {
    .logo {
        p {
            // Size text in px
            font-size: 12px;
            // Negative margins
            margin: 0px;
        }
    }
}


@font-face {
    font-family: 'Clock';
    src: url('/src/Fonts/digital-7.ttf');
}

.clock {
    font-family: 'Clock', sans-serif;
    color-scheme: light dark;
    /* no anti-aliasing */
    -webkit-font-smoothing: none;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeSpeed;
}
  